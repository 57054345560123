import React from 'react'

import './index.css'
import TableRow from './TableRow'

const PlatePrinters: React.FC = () => {
    return (
        <section className="bg-gray-100">
            <section className="bg-gray-100">
                <div className="py-16 px-4 mx-auto max-w-screen-xl flex flex-col lg:flex-row items-center">
                    <div className="w-full lg:w-1/2 mb-8 lg:mb-0 items-center text-center">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 mb-4">
                            Plate Printers
                        </h1>
                    </div>
                    <div className="w-full lg:w-1/2">
                        <img
                            className="w-full h-auto rounded-lg shadow-lg"
                            src="/images/plates/2D-3D-4D-number-plates.png"
                            alt="Hero"
                        />
                    </div>
                </div>
            </section>

            <section className="bg-gray-900">
                <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div className="font-light text-gray-500 sm:text-lg">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
                            Government look up tool
                        </h2>
                        <p className="mb-4 text-gray-400 sm:text-xl">
                            An official Government look up service can be found here -{' '}
                            <a
                                href="https://www.gov.uk/number-plate-supplier"
                                target="_blank"
                                rel="noreferrer"
                                className="text-blue-500 hover:underline">
                                Find your nearest number plate supplier (gov.uk)
                            </a>
                            .
                        </p>
                    </div>
                    <div className="mt-8 flex justify-center">
                        <img
                            className="w-40 rounded-lg w-auto h-40"
                            src="/images/dvla-logo.jfif"
                            alt="DVLA logo"
                        />
                    </div>
                </div>
            </section>

            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold">
                    RegWatch Supplier List
                </h2>
                <div className="overflow-x-auto">
                    <table className="responsive-table w-full text-sm text-left rtl:text-right text-gray-400">
                        <thead className="text-xs uppercase text-gray-400 bg-gray-700">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Seller
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Standard 2D
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    3D
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    4D
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Metal Plates
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Show Plates
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRow
                                seller="SurePlates"
                                sellerLogoSrc="/images/plates/printers/SurePlates-logo.PNG"
                                sellerWebLink="https://sureplates.com/"
                                plate2D={true}
                                plate3D={true}
                                plate4D={true}
                                plateMetal={false}
                                plateShow={false}
                            />
                            <TableRow
                                seller="Legal Show Plates"
                                sellerLogoSrc="/images/plates/printers/legalShowPlates-logo.PNG"
                                sellerWebLink="https://legalshowplates.com/"
                                plate2D={true}
                                plate3D={true}
                                plate4D={true}
                                plateMetal={false}
                                plateShow={true}
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default PlatePrinters
