import React from 'react'
import { useSelector } from 'react-redux'
import { UserState } from 'interfaces'
import { IRootState } from 'redux/Types'

enum SubscriptionLevel {
    BASIC = 'basic',
    STANDARD = 'standard',
    PRO = 'pro',
    GOAT = 'goat'
}

const subscriptionInfoMap: Record<SubscriptionLevel, number> = {
    [SubscriptionLevel.BASIC]: 1,
    [SubscriptionLevel.STANDARD]: 5,
    [SubscriptionLevel.PRO]: 20,
    [SubscriptionLevel.GOAT]: 100
}

const Profile: React.FC = () => {
    const userState = useSelector((state: IRootState) => state.user) as UserState

    return (
        <div className="bg-white w-full flex flex-col gap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931]">
            <aside className="hidden py-4 md:w-1/3 lg:w-1/4 md:block">
                <div className="sticky flex flex-col gap-2 p-4 text-sm border-r border-indigo-100 top-12">
                    <h2 className="pl-3 mb-4 text-2xl font-semibold">Settings</h2>

                    <button className="flex items-center px-3 py-2.5 font-bold bg-white  text-indigo-900 border rounded-full">
                        Profile
                    </button>
                    <button className="flex items-center px-3 py-2.5 font-semibold hover:text-indigo-900 hover:border hover:rounded-full  ">
                        Notifications
                    </button>
                </div>
            </aside>
            <main className="w-full min-h-screen py-1 md:w-2/3 lg:w-3/4">
                <div className="p-2 md:p-4">
                    <div className="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
                        <h2 className="pl-6 text-2xl font-bold sm:text-xl">Profile</h2>

                        <div className="grid max-w-2xl mx-auto mt-8">
                            <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">
                                <img
                                    className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-indigo-500"
                                    src={userState.picture_url}
                                    alt="Bordered avatar"
                                />
                            </div>

                            <div className="items-center mt-8 sm:mt-14 text-[#202142]">
                                <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                    <p>{userState.name}</p>
                                </div>
                                <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                    <p>{userState.email}</p>
                                </div>
                                <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                    <p>
                                        Subscription Level:{' '}
                                        {userState.subscription_level as SubscriptionLevel} [
                                        {
                                            subscriptionInfoMap[
                                                userState.subscription_level as SubscriptionLevel
                                            ]
                                        }
                                        ]
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Profile
