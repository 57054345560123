import React from 'react'

const FrequentlyAskedQuestions: React.FC = () => {
    return (
        <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900">
                    Frequently Asked Questions
                </h2>
                <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 md:grid-cols-2">
                    <div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900 ">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                How do I search for personalised number plates on RegWatch?
                            </h3>
                            <p className="text-gray-500">
                                RegWatch&apos;s powerful search engine allows you to effortlessly
                                explore a vast database of number plates. You can refine your search
                                results based on specific criteria, including letters, numbers, and
                                even certain combinations.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                What is the real-time availability status feature?
                            </h3>
                            <p className="text-gray-500">
                                The real-time availability status feature on RegWatch ensures that
                                you&apos;re always up-to-date with the current availability of each
                                number plate. This means you can act quickly to secure your desired
                                plate before someone else does.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                How do personalised alerts work?
                            </h3>
                            <p className="text-gray-500">
                                With RegWatch, you can create personalised alerts based on specific
                                search criteria. Once a matching number plate becomes available,
                                you&apos;ll receive a notification, ensuring you never miss out on
                                the perfect plate for your vehicle.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                Is my privacy protected on RegWatch?
                            </h3>
                            <p className="text-gray-500">
                                Absolutely. RegWatch is committed to ensuring a confidential and
                                secure environment. We prioritize your privacy and implement robust
                                measures to protect your personal information throughout your number
                                plate search and acquisition journey.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                What are the pricing plans for using RegWatch?
                            </h3>
                            <p className="text-gray-500">
                                RegWatch offers three pricing plans to suit different needs: Basic,
                                Standard, and Pro. The Basic plan is free and suitable for single
                                searches, while the Standard (£20/month) and Pro (£50/month) plans
                                offer extended features like multiple search terms and access to
                                auction and premium sites.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                Can I cancel my subscription at any time?
                            </h3>
                            <p className="text-gray-500">
                                Yes, you can cancel your subscription to RegWatch at any time. Upon
                                cancellation, you will retain access to the service until the end of
                                your current billing cycle.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                What happens if a number plate I want is no longer available?
                            </h3>
                            <p className="text-gray-500">
                                If a number plate you&apos;re interested in is no longer available,
                                RegWatch&apos;s alert system will notify you if it becomes available
                                again. Additionally, you can set alerts for similar plates to
                                increase your chances of finding the perfect match.
                            </p>
                        </div>
                        <div className="mb-10">
                            <h3 className="flex items-center mb-4 text-lg font-medium text-gray-900">
                                <svg
                                    className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                        clipRule="evenodd"></path>
                                </svg>
                                Can I transfer a number plate to a different vehicle?
                            </h3>
                            <p className="text-gray-500">
                                Yes, you can transfer a number plate to a different vehicle.
                                RegWatch provides resources and guides on how to complete this
                                process smoothly and in compliance with the relevant regulations.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FrequentlyAskedQuestions
