import React from 'react'

const About: React.FC = () => {
    return (
        <section className="bg-white">
            <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div className="font-light text-gray-500 sm:text-lg">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                        Reinventing the wheel to personalised number plate searches
                    </h2>
                    <p className="mb-4">
                        At RegWatch, we are passionate about helping you find the perfect
                        personalised number plate to make a statement on the road. Our user-friendly
                        platform empowers you to effortlessly search and discover a diverse array of
                        unique and sought-after number plates available for purchase across the
                        United Kingdom. With a commitment to simplicity and efficiency, RegWatch is
                        your go-to destination for navigating the world of distinctive registration
                        plates tailored to your preferences.
                    </p>
                    <p>
                        Join us in the pursuit of individuality on the road, where every plate tells
                        a story.
                    </p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                    <img
                        className="w-full rounded-lg"
                        src="images/cars/car-image-left.jpg"
                        alt="Bugatti Chiron - Private Plate S 41 - Left"
                    />
                    <img
                        className="mt-4 w-full lg:mt-10 rounded-lg"
                        src="images/cars/car-image-right.jpg"
                        alt="Bugatti Chiron - Private Plate S 41 - Right"
                    />
                </div>
            </div>
        </section>
    )
}

export default About
