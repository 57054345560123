import { useState, useEffect } from 'react'
import { useCookies } from 'react-cookie'

import { googleLogout } from '@react-oauth/google'

const useAuthCookie = () => {
    // Define the cookie name
    const cookieName = 'reg_watch_auth'

    // Get the initial cookie value
    const [cookies, setCookie] = useCookies([cookieName])
    const initialCookieValue = cookies[cookieName] || null

    // State to hold the current cookie value
    const [authCookie, setAuthCookie] = useState<string | null>(initialCookieValue)

    // useEffect to keep the local state in sync with the cookie value
    useEffect(() => {
        setAuthCookie(cookies[cookieName] || null)
    }, [cookies, cookieName])

    // Function to set a new cookie value
    const setAuthCookieValue = (value: string) => {
        setCookie(cookieName, value, {
            path: '/',
            expires: new Date(Date.now() + 60 * 55 * 1000),
            sameSite: 'lax',
            secure: true
        })
    }

    // Function to remove the cookie
    const removeAuthCookie = () => {
        setCookie(cookieName, null, {
            path: '/',
            expires: new Date('Thu, 01 Jan 1970 00:00:00 UTC')
        })
        googleLogout()
    }

    const getAuthCookieValue = () => {
        return authCookie
    }

    return {
        authCookie,
        setAuthCookie: setAuthCookieValue,
        removeAuthCookie,
        getAuthCookieValue
    }
}

export default useAuthCookie
