import React, { useEffect, useState } from 'react'
import useAuthCookie from 'hooks/useAuthCookie'
import { useDispatch, useSelector } from 'react-redux'
import { SearchTerm, SearchTermsState } from 'interfaces'
import { IRootState } from 'redux/Types'
import axios from 'axios'
import LoadSpinner from 'components/LoadSpinner'
import TermRow from './TermRow'
import InputRow from './InputRow'
import ACTION from '../../../redux/Actions'
import { isCacheUnixExpired } from 'utils/api'

enum ErrorType {
    General,
    NotApproved
}

// Define the type for the error state
interface ErrorState {
    error: boolean
    type: ErrorType | null // Use the ErrorType enum as the type
}

const SearchTerms: React.FC = () => {
    const dispatch = useDispatch()
    const { getAuthCookieValue } = useAuthCookie()

    const searchTermsState = useSelector(
        (state: IRootState) => state.searchTerms
    ) as SearchTermsState
    const [isLoading, setIsLoading] = useState(false)
    const [fetchError, setFetchError] = useState<ErrorState>({ error: false, type: null })

    useEffect(() => {
        const getSearchTerms = async () => {
            try {
                const cookieValue = getAuthCookieValue()
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/search-terms/get`
                const apiKey = process.env.REACT_APP_API_KEY

                const response = await axios.get(apiUrl, {
                    headers: {
                        'X-API-Key': apiKey,
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookieValue}`
                    }
                })

                if (response.status === 200) {
                    console.log(response)

                    dispatch({
                        type: ACTION.SEARCH_TERMS.ADD_SEARCH_TERMS,
                        payload: response.data.searchTerms
                    })
                }
            } catch (error: unknown) {
                console.error(error)

                if (axios.isAxiosError(error)) {
                    setFetchError({ error: true, type: ErrorType.General })
                    setIsLoading(false)
                    return
                }

                setFetchError({ error: true, type: ErrorType.General })
                setIsLoading(false)
                return
            }
        }

        if (isCacheUnixExpired(searchTermsState.updatedUnix) && !isLoading) {
            getSearchTerms()
        }
    }, [isLoading, searchTermsState.updatedUnix, getAuthCookieValue, dispatch])

    return (
        <div className="grid max-w-full mx-auto mt-8 px-0 md:px-0">
            <h2 className="text-4xl font-bold mb-8">Search Terms</h2>
            {isCacheUnixExpired(searchTermsState.updatedUnix) || isLoading ? (
                <LoadSpinner />
            ) : (
                <>
                    <p>{fetchError.type}</p>
                    <div className="flex flex-col gap-4">
                        <InputRow />
                        {searchTermsState.searchTerms.length > 0 ? (
                            searchTermsState.searchTerms
                                .slice() // Create a shallow copy to avoid mutating the original array
                                .sort((a: SearchTerm, b: SearchTerm) =>
                                    a.search_term.localeCompare(b.search_term)
                                ) // Sort alphabetically
                                .map((term: SearchTerm, index: number) => {
                                    return <TermRow key={index} term={term} />
                                })
                        ) : (
                            <p>No Search terms currently added</p>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default SearchTerms
