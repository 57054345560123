import React from 'react'
import TableRow from './TableRow'
import DvlaActionDynamicPriceCalculator from 'components/Features/DvlaActionDynamicPriceCalculator'

const DvlaAuctionPriceCalculator: React.FC = () => {
    return (
        <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <h1 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900">
                    DVLA Auction Price Calculator
                </h1>
                <div>
                    <p>
                        DVLA Auction fees are calculated based on the following factors (as of May
                        2024)
                    </p>
                    <ul className="list-disc">
                        <li>VAT on the hammer price (20%)</li>
                        <li>Buyers Premium (7%)</li>
                        <li>VAT on Buyers Premium (20%)</li>
                        <li>Assignment Fee</li>
                    </ul>
                </div>
                <div>
                    <h3 className="mb-4 text-2xl font-bold tracking-tight text-gray-900">
                        Fees Calculator
                    </h3>
                    <DvlaActionDynamicPriceCalculator />
                </div>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <h3 className="mb-4 text-2xl font-bold tracking-tight text-gray-900">
                        Quick Reference Table
                    </h3>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-400">
                        <thead className="text-xs uppercase text-gray-400 bg-gray-700">
                            <tr>
                                <th scope="col" className="px-6 py-3">
                                    Hammer Price
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Total Price
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Hammer Price VAT (20%)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Buyers Premium (7%)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Buyers Premium VAT (20%)
                                </th>
                                <th scope="col" className="px-6 py-3">
                                    Assignment Fee
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <TableRow
                                hammerPrice={250.0}
                                totalPrice={401.0}
                                hammerPriceVat={50.0}
                                buyersPremium={17.5}
                                buyersPremiumVat={3.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={500.0}
                                totalPrice={722.0}
                                hammerPriceVat={100.0}
                                buyersPremium={35.0}
                                buyersPremiumVat={7.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={750.0}
                                totalPrice={1043.0}
                                hammerPriceVat={150.0}
                                buyersPremium={52.5}
                                buyersPremiumVat={10.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={1000.0}
                                totalPrice={1364.0}
                                hammerPriceVat={200.0}
                                buyersPremium={70.0}
                                buyersPremiumVat={14.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={1250.0}
                                totalPrice={1685.0}
                                hammerPriceVat={250.0}
                                buyersPremium={87.5}
                                buyersPremiumVat={17.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={1500.0}
                                totalPrice={2006.0}
                                hammerPriceVat={300.0}
                                buyersPremium={105.0}
                                buyersPremiumVat={21.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={1750.0}
                                totalPrice={2327.0}
                                hammerPriceVat={350.0}
                                buyersPremium={122.5}
                                buyersPremiumVat={24.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={2000.0}
                                totalPrice={2648.0}
                                hammerPriceVat={400.0}
                                buyersPremium={140.0}
                                buyersPremiumVat={28.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={2250.0}
                                totalPrice={2969.0}
                                hammerPriceVat={450.0}
                                buyersPremium={157.5}
                                buyersPremiumVat={31.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={2500.0}
                                totalPrice={3290.0}
                                hammerPriceVat={500.0}
                                buyersPremium={175.0}
                                buyersPremiumVat={35.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={2750.0}
                                totalPrice={3611.0}
                                hammerPriceVat={550.0}
                                buyersPremium={192.5}
                                buyersPremiumVat={38.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={3000.0}
                                totalPrice={3932.0}
                                hammerPriceVat={600.0}
                                buyersPremium={210.0}
                                buyersPremiumVat={42.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={3250.0}
                                totalPrice={4253.0}
                                hammerPriceVat={650.0}
                                buyersPremium={227.5}
                                buyersPremiumVat={45.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={3500.0}
                                totalPrice={4574.0}
                                hammerPriceVat={700.0}
                                buyersPremium={245.0}
                                buyersPremiumVat={49.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={3750.0}
                                totalPrice={4895.0}
                                hammerPriceVat={750.0}
                                buyersPremium={262.5}
                                buyersPremiumVat={52.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={4000.0}
                                totalPrice={5216.0}
                                hammerPriceVat={800.0}
                                buyersPremium={280.0}
                                buyersPremiumVat={56.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={4250.0}
                                totalPrice={5537.0}
                                hammerPriceVat={850.0}
                                buyersPremium={297.5}
                                buyersPremiumVat={59.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={4500.0}
                                totalPrice={5858.0}
                                hammerPriceVat={900.0}
                                buyersPremium={315.0}
                                buyersPremiumVat={63.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={4750.0}
                                totalPrice={6179.0}
                                hammerPriceVat={950.0}
                                buyersPremium={332.5}
                                buyersPremiumVat={66.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={5000.0}
                                totalPrice={6500.0}
                                hammerPriceVat={1000.0}
                                buyersPremium={350.0}
                                buyersPremiumVat={70.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={5250.0}
                                totalPrice={6821.0}
                                hammerPriceVat={1050.0}
                                buyersPremium={367.5}
                                buyersPremiumVat={73.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={5500.0}
                                totalPrice={7142.0}
                                hammerPriceVat={1100.0}
                                buyersPremium={385.0}
                                buyersPremiumVat={77.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={5750.0}
                                totalPrice={7463.0}
                                hammerPriceVat={1150.0}
                                buyersPremium={402.5}
                                buyersPremiumVat={80.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={6000.0}
                                totalPrice={7784.0}
                                hammerPriceVat={1200.0}
                                buyersPremium={420.0}
                                buyersPremiumVat={84.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={6250.0}
                                totalPrice={8105.0}
                                hammerPriceVat={1250.0}
                                buyersPremium={437.5}
                                buyersPremiumVat={87.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={6500.0}
                                totalPrice={8426.0}
                                hammerPriceVat={1300.0}
                                buyersPremium={455.0}
                                buyersPremiumVat={91.0}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={6750.0}
                                totalPrice={8747.0}
                                hammerPriceVat={1350.0}
                                buyersPremium={472.5}
                                buyersPremiumVat={94.5}
                                assignmentFee={80.0}
                            />
                            <TableRow
                                hammerPrice={7000.0}
                                totalPrice={9068.0}
                                hammerPriceVat={1400.0}
                                buyersPremium={490.0}
                                buyersPremiumVat={98.0}
                                assignmentFee={80.0}
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default DvlaAuctionPriceCalculator
