import React from 'react'
import NumberPlate from 'components/NumberPlate'
import dateFormat from 'dateformat'
import EditIcon from 'components/Assets/Icons/EditIcon'
import { SearchResult } from 'interfaces'
import { formatPrice } from 'utils/ViewableFormats'

interface ResultRowProps {
    result: SearchResult
}

const ResultRow: React.FC<ResultRowProps> = ({ result }) => {
    console.log(result.auction ? result.auctionStartPrice : result.price)

    return (
        <div className="border p-4 rounded-lg bg-white shadow-md">
            <div className="flex items-center justify-between">
                <NumberPlate text={result.plate} />
                <NumberPlate text={result.search_term} />
            </div>
            <div>
                <p>
                    {result.auction
                        ? formatPrice(result.auctionStartPrice)
                        : formatPrice(result.price)}
                </p>
                <p>
                    {result.webLink ? (
                        <a
                            className="font-medium text-blue-600"
                            href={result.webLink}
                            target="_blank"
                            rel="noreferrer">
                            {result.seller}
                        </a>
                    ) : (
                        result.seller
                    )}
                </p>
            </div>
            <div>
                <p>{dateFormat(result.created, 'dddd dS mmmm yyyy - h:MM TT')}</p>
                {result.created !== result.updated && (
                    <p className="inline-flex items-baseline">
                        <EditIcon />
                        {dateFormat(result.updated, 'dddd dS mmmm yyyy - h:MM TT')}
                    </p>
                )}
            </div>
        </div>
    )
}

export default ResultRow
