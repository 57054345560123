import ACTION from '../Actions'
import { SearchTermsState, SearchTerm } from 'interfaces'

const initialState: SearchTermsState = {
    searchTerms: [],
    updatedUnix: 0
}

interface AddSearchTermsAction {
    type: typeof ACTION.SEARCH_TERMS.ADD_SEARCH_TERMS
    payload: SearchTerm[]
}

interface AddSingleSearchTermAction {
    type: typeof ACTION.SEARCH_TERMS.ADD_SINGLE_SEARCH_TERM
    payload: SearchTerm
}

interface RemoveSingleSearchTermAction {
    type: typeof ACTION.SEARCH_TERMS.REMOVE_SINGLE_SEARCH_TERM
    payload: string
}

interface RemoveAllReduxAction {
    type: typeof ACTION.REMOVE_ALL_REDUX
}

type SearchTermsActions =
    | AddSearchTermsAction
    | AddSingleSearchTermAction
    | RemoveSingleSearchTermAction
    | RemoveAllReduxAction

const searchTermsReducer = (
    state: SearchTermsState = initialState,
    action: SearchTermsActions
): SearchTermsState => {
    switch (action.type) {
        case ACTION.SEARCH_TERMS.ADD_SEARCH_TERMS:
            return {
                ...state,
                searchTerms: action.payload,
                updatedUnix: Math.floor(Date.now() / 1000)
            }
        case ACTION.SEARCH_TERMS.ADD_SINGLE_SEARCH_TERM:
            return {
                ...state,
                searchTerms: [...state.searchTerms, action.payload]
            }
        case ACTION.SEARCH_TERMS.REMOVE_SINGLE_SEARCH_TERM:
            return {
                ...state,
                searchTerms: state.searchTerms.filter((term) => term.search_term !== action.payload)
            }
        case ACTION.REMOVE_ALL_REDUX:
            return initialState
        default:
            return state
    }
}

export default searchTermsReducer
