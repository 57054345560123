export const formatPrice = (price: string | null): string => {
    // Check if the input is null
    if (price === null) return '£-'

    if (price === 'Pending Start Price') return '£ Pending Start Price'

    // Extract the number from the input string using a regular expression
    const match = price.match(/[\d,]+(\.\d{1,2})?/)

    // If there's no match, return "£-"
    if (!match) return '£-'

    // Parse the matched number to a float
    const parsedPrice = parseFloat(match[0].replace(/,/g, ''))

    // Check if the parsed price is a valid number
    if (isNaN(parsedPrice)) {
        throw new Error('Invalid price input')
    }

    // Format the number to a currency string
    const formattedPrice = parsedPrice.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP'
    })

    return formattedPrice
}
