import React from 'react'
import SellerCard from './SellerCard'

const OtherPlateSites: React.FC = () => {
    return (
        <section className="bg-gray-100">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <section className="bg-gray-100">
                    <div className="px-4 mx-auto max-w-screen-xl flex flex-col lg:flex-row items-center">
                        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 items-center text-center">
                            <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 mb-4">
                                Other Plate Sites
                            </h2>
                        </div>
                        <div className="w-full lg:w-1/2">
                            <img
                                className="w-full h-auto rounded-lg shadow-lg"
                                src="/images/plates/plate-background-2.jpg"
                                alt="Hero"
                            />
                        </div>
                    </div>
                </section>

                <div className="my-12">
                    <SellerCard
                        seller="Mathewsons"
                        details="Mainly focus on classic cars and memorabilia, but do a number plate auction very often. The prices here can be a little higher than market rates, but if being your perfect plate it may be worth while."
                        logoImg="/images/plates/sellers/Mathewsons-logo.png"
                        webLink="https://www.mathewsons.co.uk/upcoming-auctions/"
                        sellingMethods="Auction"
                    />
                    <SellerCard
                        seller="Brightwells"
                        details="Around 4 times a year they will do a private number plate auction, and do have some good plates which can be found at a decent price. There main focus is on the commerical side with vehicles and plate."
                        logoImg="/images/plates/sellers/brightwells-logo.png"
                        webLink="https://www.brightwells.com/"
                        sellingMethods="Auction"
                    />
                    {/* <SellerCard
                        seller=""
                        details=""
                        logoImg=""
                        webLink=""
                        sellingMethods=""
                    /> */}
                </div>
            </div>
        </section>
    )
}

export default OtherPlateSites
