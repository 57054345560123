/*
    The below strings were generated with the following code
    const originalString = `/images/cars/REGWATCH_CarReg_`
    const images = Array.from({ length: 54 }, (_, index) => originalString + index + '.png')
*/

export const REGWATCH_IMAGES: string[] = [
    '/images/cars/regwatch_plates/REGWATCH_CarReg_1.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_2.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_3.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_4.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_5.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_6.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_7.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_8.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_9.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_10.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_11.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_12.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_13.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_14.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_15.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_16.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_17.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_18.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_19.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_20.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_21.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_22.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_23.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_24.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_25.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_26.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_27.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_28.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_29.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_30.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_31.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_32.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_33.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_34.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_35.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_36.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_37.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_38.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_39.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_40.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_41.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_42.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_43.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_44.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_45.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_46.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_47.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_48.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_49.png',
    '/images/cars/regwatch_plates/REGWATCH_CarReg_50.png'
]
