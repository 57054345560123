import * as SEARCH_RESULTS from './searchResultsTypes'
import * as SEARCH_TERMS from './searchTermsTypes'
import * as USER from './userActionTypes'
import * as GENERAL from './generalTypes'

const ACTION = {
    SEARCH_RESULTS,
    SEARCH_TERMS,
    USER,
    ...GENERAL
}

export default ACTION
