import React from 'react'
import '../NumberPlate.css'

interface NumberPlateInputProps {
    name: string
    placeholder: string
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const NumberPlateInput: React.FC<NumberPlateInputProps> = ({
    name,
    placeholder,
    value,
    onChange
}) => {
    return (
        <input
            type="text"
            className="number-plate number-plate-input"
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
        />
    )
}

export default NumberPlateInput
