import React from 'react'
import * as tableData from './tableData'
import ToggleableTable from 'components/layouts/ToggleableTable'

const GuideToPlates: React.FC = () => {
    return (
        <section className="bg-gray-100">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                {/* <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900 text-center">
                    Guide to Plates
                </h2> */}
                <section className="bg-gray-100">
                    <div className="px-4 mx-auto max-w-screen-xl flex flex-col lg:flex-row items-center">
                        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 items-center text-center">
                            <h2 className="text-4xl tracking-tight font-extrabold text-gray-900 mb-4">
                                Guide to Plates
                            </h2>
                        </div>
                        <div className="w-full lg:w-1/2">
                            <img
                                className="w-full h-auto rounded-lg shadow-lg"
                                src="/images/plates/plate-background.PNG"
                                alt="Hero"
                            />
                        </div>
                    </div>
                </section>

                {/* Navigation List */}
                <nav className="mb-12">
                    <ul className="list-disc pl-6 space-y-2">
                        <li>
                            <a href="#new-style-plates" className="text-blue-500 hover:underline">
                                New Style Number Plates [2001 - present]
                            </a>
                        </li>
                        <li>
                            <a href="#prefix-plates" className="text-blue-500 hover:underline">
                                Prefix Number Plates [1983–2001]
                            </a>
                        </li>
                        <li>
                            <a href="#suffix-plates" className="text-blue-500 hover:underline">
                                Suffix Number Plates [1963–1983]
                            </a>
                        </li>
                        <li>
                            <a href="#dateless-plates" className="text-blue-500 hover:underline">
                                Dateless Number Plates
                            </a>
                        </li>
                        <li>
                            <a href="#ni-plates" className="text-blue-500 hover:underline">
                                Northern Irish Number Plates
                            </a>
                        </li>
                    </ul>
                </nav>

                <div className="mb-12" id="new-style-plates">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                        New Style Number Plates [2001 - present]
                    </h3>
                    <p className="mb-4">
                        The current registration system we have in Great Britain started on the 1st
                        of September, 2001. This new registration index is made up of seven
                        characters.
                    </p>
                    <div className="flex justify-center mb-4">
                        <img
                            className="w-full sm:w-1/2"
                            src="/images/plates/examples/reg-current-style-example.png"
                            alt="Plate example - current"
                        />
                    </div>
                    <p className="mb-4">
                        It’s the 3rd and 4th number here that is our “age identifier” AKA the year
                        it was issued. This two-digit age identifier changes twice a year at the
                        beginning of March and again at the beginning of September.
                    </p>
                    <p className="mb-4">
                        If issued between March and August, the age identifier will be the last two
                        digits of that year (e.g. “02” = March 2002 - August 2002). For
                        registrations issued between September and February the following year, the
                        3rd number changes to a “5” and the 4th will be the last digit of the year
                        it was first issued (e.g. “52” = September 2002 - February 2003).
                    </p>
                    <ToggleableTable
                        headerElements={tableData.currentStyleHeaderElements}
                        rowElements={tableData.currentStyleRowElements}
                        showText="Show Current Style Number Plates"
                        hiddenText="Hide Current Style Number Plates"
                    />
                </div>
                <div className="mb-12" id="prefix-plates">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                        Prefix Number Plates [1983–2001]
                    </h3>
                    <p className="mb-4">
                        By 1982, Britain had gone through the alphabet it was using for its age
                        identifier on Suffix style plates (see Suffix section below). So, starting
                        in 1983, they decided to move this letter to the beginning of the
                        registration – giving us the Prefix plates we old-timers know all too well.
                    </p>
                    <div className="flex justify-center mb-4">
                        <img
                            className="w-full sm:w-1/2"
                            src="/images/plates/examples/reg-prefix-style-example.png"
                            alt="Plate example - prefix"
                        />
                    </div>
                    <ToggleableTable
                        headerElements={tableData.prefixHeaderElements}
                        rowElements={tableData.prefixRowElements}
                        showText="Show Prefix Age Identifiers"
                        hiddenText="Hide Prefix Age Identifiers"
                    />
                    <p className="mb-4">
                        You might be thinking “Where are letters I, O, Q, U and Z?” These letters
                        have never been issued as year identifiers down to the fact that they all
                        look too similar to other numbers and letters. ‘I’ looks like the number
                        ‘1’, ‘O’ and ‘Q’ look like ‘0’ (zero) and poor ‘Z’ looks a bit like a ‘2’.
                        And ‘U’ got the boot as it looked too much like the letter ‘V’.
                    </p>
                </div>
                <div className="mb-12" id="suffix-plates">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                        Suffix Number plates [1963–1983]
                    </h3>
                    <p className="mb-4">
                        Suffix plates were first introduced in 1963 to alleviate the problem of the
                        British public running out of registrations. It took the previous scheme
                        that ran from 1932 (a three-letter combination followed by a sequence number
                        from 1 to 999) and added a letter suffix, which became the age identifier
                        (see example below). This final letter – why it’s called a suffix - changed
                        on the 1st of January each year, with the first letter being A for 1963.
                    </p>
                    <div className="flex justify-center mb-4">
                        <img
                            className="w-full sm:w-1/2"
                            src="/images/plates/examples/reg-suffix-style-example.png"
                            alt="Plate example - suffix"
                        />
                    </div>
                    <ToggleableTable
                        headerElements={tableData.suffixHeaderElements}
                        rowElements={tableData.suffixRowElements}
                        showText="Show Suffix Age Identifiers"
                        hiddenText="Hide Suffix Age Identifiers"
                    />
                </div>
                <div className="mb-12" id="dateless-plates">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                        Dateless Number Plates
                    </h3>
                    <p className="mb-4">
                        The earliest UK dateless number plates were issued using letters to stand
                        for the region of issue, and sequential numbers as unique identifiers. The
                        lack of a letter or number combination to indicate the year of issue means
                        that these plates are often referred to as dateless number plates.
                    </p>
                    <p className="mb-4">
                        As these plates were issued and managed locally by County Council offices,
                        number and letter sequences ran out at different rates in different parts of
                        the country. Due to differing local demand, there are a wide variety of
                        types of dateless plates with a lot of overlap between the issue periods for
                        different formats.
                    </p>
                    <p className="mb-4">
                        One of the UK’s first registrations, A1 was famously bought by Earl Russell.
                        He reputedly queued outside the London County Council offices all night to
                        acquire this number!
                    </p>
                    <h4 className="text-lg font-bold text-gray-800 mb-2">1903 to 1932</h4>
                    <p className="mb-4">
                        In 1903 the UK’s first registration numbers were issued. Number plates made
                        during this period consisted of:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>
                            A prefix of one or two letters - This was used to reference the local
                            authority issuing the registration.
                        </li>
                        <li>A space.</li>
                        <li>
                            A number from 1 to 9999 - These were issued sequentially by the issuing
                            office.
                        </li>
                    </ul>
                    <div className="flex justify-center mb-4">
                        <img
                            className="w-full sm:w-1/2"
                            src="/images/plates/examples/reg-dateless-1-style-example.png"
                            alt="Plate example - Dateless (1)"
                        />
                    </div>
                    <p className="mb-4">
                        The issuing County Council offices in England and Wales were each given a
                        one or two letter prefix code. For example: London used “A”, Birmingham “O”,
                        up to Rutland with “FP”. Because of this it is hard to say what the UK’s
                        first dateless number plate was. A1 was certainly the first issued in
                        London. Scotland and Ireland had their own 2-letter sequences starting with
                        “S” and “I” respectively. The second letter was allocated alphabetically by
                        county. For example Aberdeenshire was allocated “SA”, Argyll “SB”, etc. In
                        Ireland, Antrim received “IA”, Armagh “IB” and so on.
                    </p>
                    <p className="mb-4">
                        When plate 9999 was reached by a licensing authority it was allocated
                        another unused registration letter sequence. There was no pattern to these
                        subsequent allocations other than “first come, first served”.
                    </p>
                    <p className="mb-4">
                        The 1901 census was used to order issuing offices by the population size
                        they covered. This order determined the prefix code each office was given.
                    </p>
                    <h4 className="text-lg font-bold text-gray-800 mb-2">1903 to 1932</h4>
                    <p className="mb-4">
                        Due to the booming – and unexpected – increase in UK motor vehicles, by 1932
                        the available numbers from the original format were running out! A new
                        format was devised, this time made up of:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>
                            A three letter suffix, e.g ABC, MCR - These were taken from the series
                            “AAA” to “YYY”.
                        </li>
                        <li>A space.</li>
                        <li>
                            A number from 1 to 999. These were again issued sequentially by each
                            local office.
                        </li>
                    </ul>
                    <div className="flex justify-center mb-4">
                        <img
                            className="w-full sm:w-1/2"
                            src="/images/plates/examples/reg-dateless-2-style-example.png"
                            alt="Plate example - Dateless (2)"
                        />
                    </div>
                    <p className="mb-4">
                        The new plates retained 2-letter area codes – any remaining single letter
                        codes were replaced. However, the area codes were now the 2nd and 3rd
                        letters in the sequence of 3. The first letter was changed sequentially when
                        the previous letter had used up all the available number combinations.
                    </p>
                    <p className="mb-4">
                        The letters I, Q, and Z were not used. They were considered too easy to
                        mistake for letters or numbers, or were reserved for special use.
                    </p>
                    <h4 className="text-lg font-bold text-gray-800 mb-2">1950s & 1960s</h4>
                    <p className="mb-4">
                        In some areas the available registration numbers within this scheme started
                        to run out in the 1950’s. In those places a reversed sequence was
                        introduced. For example “1 AAA” to “999 YYY”. The ever-increasing popularity
                        of the car can be gauged by noting that even these reversed sequences ran
                        out within ten years! By the beginning of the 1960s a further change was
                        made in very popular areas introducing 1 to 4 number registration number
                        sequences and reverting to one and two letter area codes, but in the reverse
                        direction of the original scheme. For example “1 A to “9999 YY”. Any
                        un-issued ‘dateless’ combinations began to be auctioned by the Government
                        Department of Transport (DVLA) in 1989 and continue to be to this day and
                        will be for the foreseeable future raising tens of millions of pounds in
                        revenue each year.
                    </p>
                    <div className="flex justify-center mb-4">
                        <img
                            className="w-full sm:w-1/2"
                            src="/images/plates/examples/reg-dateless-3-style-example.png"
                            alt="Plate example - Dateless (3)"
                        />
                    </div>
                </div>
                <div className="mb-12" id="ni-plates">
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                        Northern Irish Number Plates
                    </h3>
                    <p className="mb-4">
                        Vehicles registered in Northern Ireland are assigned a form of dateless
                        registration, introduced in 1903. These registrations follow the format AZ*
                        where AZ is a two- or three-letter code containing either an I or a Z, and *
                        represents a number between 1 and 9999.
                    </p>
                    <div className="flex justify-center mb-4">
                        <img
                            className="w-full sm:w-1/2"
                            src="/images/plates/examples/reg-ni-style-example.png"
                            alt="Plate example - Northern Irish"
                        />
                    </div>
                    <p className="mb-4">
                        As of 2014, the DVLANI has merged with the UK-wide DVLA so that all vehicle
                        records and registration transfers are now handled by the DVLA in Swansea.
                        Now, registration transfers are completed a lot faster and significantly
                        cheaper than they previously were. All Irish registrations are dateless;
                        they do not refer to the year that a vehicle was first registered. This
                        means that an Irish registration can be assigned to any age of vehicle which
                        has been registered in the UK.
                    </p>
                    <p className="mb-4">
                        Irish registrations are more readily available than Dateless Style
                        registrations and therefore tend to be cheaper in price. They provide the
                        most cost-effective solution to customers wishing to hide the age of their
                        vehicle with cover plates as Northern Irish style registrations can be
                        transferred onto vehicles in England, Wales and Scotland. Irish
                        registrations are easily identified as they will always contain an I or Z.
                        They are often used as cover plates to hide the age of a vehicle, such as
                        NFZ5329, or CFZ6928. They are also used to spell short names or words that
                        would otherwise be difficult to obtain, such as BAZ, GAZ, or MIA.
                    </p>
                </div>
            </div>
        </section>
    )
}

export default GuideToPlates
