import React from 'react'

interface YouTubeCardProps {
    title: string
    thumbnailUrl: string
    description: string
    videoOwner: string
    videoUrl: string
}

const YouTubeCard: React.FC<YouTubeCardProps> = ({
    title,
    thumbnailUrl,
    description,
    videoOwner,
    videoUrl
}) => {
    return (
        <div className="w-full sm:w-1/2 p-2">
            <div className="border p-4 rounded-lg bg-white shadow-md h-full flex flex-col">
                <div className="flex flex-col md:flex-row flex-grow">
                    {/* Thumbnail Image */}
                    <div className="flex-shrink-0">
                        <img
                            className="w-full md:w-48 h-48 md:h-auto object-cover rounded-lg"
                            src={thumbnailUrl}
                            alt="Video Thumbnail"
                        />
                    </div>
                    {/* Video Details */}
                    <div className="mt-4 md:mt-0 md:ml-4 flex flex-col flex-grow">
                        <div className="flex-grow">
                            {/* Title */}
                            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
                            {/* Description */}
                            <p className="mt-2 text-gray-600">{description}</p>
                        </div>
                        <div className="mt-4 flex items-center justify-between">
                            {/* Video Owner */}
                            <div className="text-gray-500">by {videoOwner}</div>
                            {/* YouTube Link */}
                            <a
                                href={videoUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-red-600 hover:text-red-800">
                                <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24">
                                    <path d="M23.499 6.203a2.972 2.972 0 00-2.1-2.105C19.651 3.62 12 3.62 12 3.62s-7.651 0-9.398.478a2.972 2.972 0 00-2.101 2.105C0 8.065 0 12 0 12s0 3.935.501 5.797a2.972 2.972 0 002.101 2.105c1.748.478 9.398.478 9.398.478s7.651 0 9.398-.478a2.972 2.972 0 002.1-2.105C24 15.935 24 12 24 12s0-3.935-.501-5.797zM9.546 15.568V8.432L15.818 12 9.546 15.568z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YouTubeCard
