import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SearchResultsState, SearchResult } from 'interfaces'
import axios from 'axios'
import { IRootState } from 'redux/Types'
import useAuthCookie from 'hooks/useAuthCookie'
import LoadSpinner from 'components/LoadSpinner'
import ResultRow from './ResultRow'
import NumberPlate from 'components/NumberPlate'
import { isCacheUnixExpired } from 'utils/api'

enum ErrorType {
    General,
    NotApproved
}

interface ErrorState {
    error: boolean
    type: ErrorType | null
}

const getUniqueSearchTermsFromResults = (results: SearchResult[]): string[] => {
    const searchTermSet = new Set<string>()
    results.forEach((result) => {
        searchTermSet.add(result.search_term)
    })
    return Array.from(searchTermSet)
}

const Results: React.FC = () => {
    const dispatch = useDispatch()
    const { getAuthCookieValue } = useAuthCookie()

    const searchResultsState = useSelector(
        (state: IRootState) => state.searchResults
    ) as SearchResultsState

    const [isLoading, setIsLoading] = useState(false)
    const [fetchError, setFetchError] = useState<ErrorState>({ error: false, type: null })
    const [selectedSearchTerms, setSelectedSearchTerms] = useState<string[]>([])
    const [sortOption, setSortOption] = useState<string>('newestFirst')

    useEffect(() => {
        const getResults = async () => {
            try {
                const cookieValue = getAuthCookieValue()
                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/search-results/get`
                const apiKey = process.env.REACT_APP_API_KEY

                const response = await axios.get(apiUrl, {
                    headers: {
                        'X-API-Key': apiKey,
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookieValue}`
                    }
                })

                if (response.status === 200) {
                    dispatch({
                        type: 'ADD_SEARCH_RESULTS',
                        payload: response.data.results
                    })
                }
            } catch (error: unknown) {
                console.error(error)
                if (axios.isAxiosError(error)) {
                    setFetchError({ error: true, type: ErrorType.General })
                    setIsLoading(false)
                    return
                }

                setFetchError({ error: true, type: ErrorType.General })
                setIsLoading(false)
                return
            }
        }

        if (isCacheUnixExpired(searchResultsState.updatedUnix) && !isLoading) {
            getResults()
        }
    }, [isLoading, searchResultsState.updatedUnix, getAuthCookieValue, dispatch])

    const searchResultsUniqueTerms = useMemo(
        () => getUniqueSearchTermsFromResults(searchResultsState.searchResults as SearchResult[]),
        [searchResultsState.searchResults]
    )

    useEffect(() => {
        if (searchResultsState.searchResults.length > 0) {
            const uniqueSearchTerms = getUniqueSearchTermsFromResults(
                searchResultsState.searchResults as SearchResult[]
            )
            setSelectedSearchTerms(uniqueSearchTerms)
        }
    }, [searchResultsState.searchResults])

    const handleCheckboxChange = (term: string) => {
        setSelectedSearchTerms((prevSelectedTerms) => {
            if (prevSelectedTerms.includes(term)) {
                // Prevent unselecting the last checkbox
                if (prevSelectedTerms.length === 1) {
                    return prevSelectedTerms
                }
                return prevSelectedTerms.filter((t) => t !== term)
            } else {
                return [...prevSelectedTerms, term]
            }
        })
    }

    const handleDivClick = (term: string) => {
        handleCheckboxChange(term)
    }

    const handleDivKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, term: string) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault()
            handleCheckboxChange(term)
        }
    }

    const handleSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSortOption(event.target.value)
    }

    const sortResults = (results: SearchResult[]): SearchResult[] => {
        switch (sortOption) {
            case 'newestFirst':
                return [...results].sort(
                    (a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()
                )
            case 'oldestFirst':
                return [...results].sort(
                    (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
                )
            case 'alphabeticalAZ':
                return [...results].sort((a, b) => a.plate.localeCompare(b.plate))
            case 'alphabeticalZA':
                return [...results].sort((a, b) => b.plate.localeCompare(a.plate))
            default:
                return results
        }
    }

    const filteredResults = useMemo(
        () =>
            selectedSearchTerms.length > 0
                ? sortResults(
                      searchResultsState.searchResults.filter((result) =>
                          selectedSearchTerms.includes(result.search_term)
                      )
                  )
                : sortResults(searchResultsState.searchResults),
        [searchResultsState.searchResults, selectedSearchTerms, sortOption]
    )

    return (
        <div className="grid max-w-2xl mx-auto mt-8">
            <h2 className="text-4xl font-bold">Results</h2>
            {isCacheUnixExpired(searchResultsState.updatedUnix) || isLoading ? (
                <LoadSpinner />
            ) : (
                <>
                    <p>{fetchError.type}</p>
                    <div className="flex flex-col gap-4">
                        <div>
                            <h3 className="text-xl font-bold">Filter</h3>
                            <div>
                                <h4 className="text-l font-bold">Search Terms</h4>
                                <div className="flex flex-wrap">
                                    {searchResultsUniqueTerms.map((term, index) => {
                                        const htmlFor = `search-results-filter-${term}`
                                        return (
                                            <div
                                                key={index}
                                                className="mr-2 cursor-pointer"
                                                role="button"
                                                tabIndex={0}
                                                onKeyDown={(event) => handleDivKeyDown(event, term)}
                                                onClick={() => handleDivClick(term)}>
                                                <input
                                                    className="mr-1"
                                                    type="checkbox"
                                                    name={htmlFor}
                                                    value={term}
                                                    tabIndex={-1}
                                                    checked={selectedSearchTerms.includes(term)}
                                                    onChange={(e) => {
                                                        e.stopPropagation()
                                                        handleCheckboxChange(term)
                                                    }}
                                                />
                                                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                                                <label htmlFor={htmlFor}>
                                                    <NumberPlate text={term} />
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div>
                                <h4 className="text-l font-bold">Sort By</h4>
                                <select
                                    className="border p-2 rounded"
                                    value={sortOption}
                                    onChange={handleSortChange}>
                                    <option value="newestFirst">Time Latest</option>
                                    <option value="oldestFirst">Time Oldest</option>
                                    <option value="alphabeticalAZ">alphabeticalAZ</option>
                                    <option value="alphabeticalZA">alphabeticalZA</option>
                                </select>
                            </div>
                        </div>
                        {filteredResults.length > 0 ? (
                            filteredResults.map((result: SearchResult, index: number) => {
                                return <ResultRow result={result} key={index} />
                            })
                        ) : (
                            <p>No Search results currently found</p>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

export default Results
