import React from 'react'

const OwnershipGuide: React.FC = () => {
    return (
        <div>
            <section className="bg-gray-100">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <section className="bg-gray-100">
                        <div className="px-4 mx-auto max-w-screen-xl flex flex-col lg:flex-row items-center text-center lg:text-left">
                            <div className="w-full lg:w-1/2 mb-8 lg:mb-0 items-center text-center">
                                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 mb-4">
                                    Ownership Guide
                                </h1>
                            </div>
                            <div className="w-full lg:w-1/2">
                                <img
                                    className="w-full h-auto rounded-lg shadow-lg"
                                    src="/images/plates/dvla-v770-example-2.jpg"
                                    alt="Hero"
                                />
                            </div>
                        </div>
                    </section>
                </div>
            </section>
            <section className="bg-gray-900">
                <div className="px-4 py-8 mx-auto max-w-screen-lg">
                    <h2 className="text-3xl font-extrabold text-white mb-8 text-center">
                        Document Types
                    </h2>
                    <div className="flex flex-wrap justify-center gap-8">
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-sm mx-auto">
                            <div className="bg-pink-100 p-4 rounded-t-lg">
                                <h3 className="text-2xl font-semibold text-gray-800">
                                    Certificate of Entitlement (Form V750)
                                </h3>
                            </div>
                            <img
                                className="w-full h-auto rounded-lg shadow-md mt-4"
                                src="/images/plates/dvla-form-v750-certificate-of-entitlement-sample.png"
                                alt="Certificate of Entitlement"
                            />
                            <p className="text-gray-600 mt-4">
                                The Certificate of Entitlement (Form V750) is a pink document issued
                                by the DVLA for a vehicle registration that hasn&apos;t been put on
                                a vehicle before. A V750 shows that the original purchaser is
                                entitled to the number plate for 10 years.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-sm mx-auto">
                            <div className="bg-green-100 p-4 rounded-t-lg">
                                <h3 className="text-2xl font-semibold text-gray-800">
                                    Retention Document (Form V778)
                                </h3>
                            </div>
                            <img
                                className="w-full h-auto rounded-lg shadow-md mt-4"
                                src="/images/plates/dvla-form-v778-retention-document-sample.png"
                                alt="Retention Document"
                            />
                            <p className="text-gray-600 mt-4">
                                The Retention Document (Form V778) is a green document also issued
                                by the DVLA for a registration number that has been previously
                                assigned to a vehicle. A V778 shows that the grantee is entitled to
                                the number plate for 10 years.
                            </p>
                        </div>
                        <div className="bg-white p-6 rounded-lg shadow-lg w-full md:max-w-sm mx-auto">
                            <div className="bg-blue-100 p-4 rounded-t-lg">
                                <h3 className="text-2xl font-semibold text-gray-800">
                                    Vehicle Logbook (Form V5C)
                                </h3>
                            </div>
                            <img
                                className="w-full h-auto rounded-lg shadow-md mt-4"
                                src="/images/plates/dvla-v5c-sample.jpg"
                                alt="Vehicle Logbook"
                            />
                            <p className="text-gray-600 mt-4">
                                The V5C logbook is also known as the registration document, vehicle
                                logbook, or simply the V5 form. It is the most important document
                                you will possess as a car owner in the UK and it is essential to
                                keep hold of it.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-gray-100">
                <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <h2 className="text-3xl font-extrabold text-gray-900 mb-8 text-center">
                        Top Tips
                    </h2>
                    <div className="bg-white p-8 rounded-lg shadow-lg">
                        <div className="space-y-8">
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Retention
                                </h3>
                                <p className="text-gray-600 mb-2">
                                    If the plate is on retention make sure the address and details
                                    are up to date.
                                </p>
                                <p className="text-gray-600">
                                    If you&apos;re holding a plate on retention, DO NOT let the
                                    certificate expire, as once it expires the ownership of the
                                    plate is no longer yours.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    New Car
                                </h3>
                                <p className="text-gray-600 mb-2">
                                    Once you assign a plate to a car from a certificate, it&apos;s
                                    now officially part of the car, and in the example of where you
                                    are not the registered keeper of the car, now the plate is part
                                    of the car and the registered keeper.
                                </p>
                                <p className="text-gray-600">
                                    When assigning a plate to a car, the car MUST have an MOT and be
                                    taxed (or SORN continuously for the past five years).
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    Selling Car
                                </h3>
                                <p className="text-gray-600 mb-2">
                                    Never sell a car with a private plate still assigned to the car,
                                    as once the V5C is not in your name you&apos;re unable to put
                                    the plate on retention.
                                </p>
                                <p className="text-gray-600">
                                    If wishing to buy a private plate from someone who is not an
                                    official DVLA approved dealer, for example via Facebook or even
                                    eBay, you&apos;re able to use a broker who can aid in ensuring
                                    that both parties are kept safe.
                                </p>
                                <p className="text-gray-600">
                                    Never trust anyone but yourself or a DVLA registered broker to
                                    remove a plate on your behalf. For example, if you sell a car
                                    and the buyer says they&apos;ll retain the plate and give you
                                    the details this can&apos;t be trusted legally, meaning
                                    you&apos;ll be at risk of loosing your plate.
                                </p>
                            </div>
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                                    General
                                </h3>
                                <p className="text-gray-600 mb-2">
                                    If you car is stolen, just like with any crime you should report
                                    the incident to the police immediately. There is a risk that
                                    your car could be involved in a fire or collision while it is
                                    away from you. As it is not permitted to transfer a registration
                                    from a car that has been written off, you should inform DVLA of
                                    the theft and apply to have your private registration number
                                    placed on a retention document. <br /> Your application to
                                    retain the number must be submitted within 2 years and 6 months
                                    of reporting your vehicle stolen to the DVLA and the vehicle
                                    must have had a valid MOT certificate and up-to-date vehicle tax
                                    at the time of the incident. <br /> If you do not get your
                                    stolen car back, you must wait 6 months before you can transfer
                                    your private number plate from your retention certificate to
                                    another car and the transfer process must be conducted by post.
                                    On the other hand, if you do get your car back, you can carry
                                    out the transfer straight away.
                                </p>
                                <p className="text-gray-600">
                                    If you plate if cloned, it can be hard to find out if your
                                    number plate has actually been cloned. However, if you start to
                                    receive fines that you are not responsible for then that can be
                                    a strong indication that your number has been cloned. In such a
                                    case, it is important to collect as much evidence as possible to
                                    show that you were not driving the vehicle that committed the
                                    offences, such as your locations at the times of the offences.
                                    You should also report suspicions of cloning to the police and
                                    the DVLA.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default OwnershipGuide
