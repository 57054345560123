import React, { useState } from 'react'

interface ToggleableTableProps {
    headerElements: string[]
    rowElements: Array<Array<string>>
    showText: string
    hiddenText: string
}

const ToggleableTable: React.FC<ToggleableTableProps> = ({
    headerElements,
    rowElements,
    showText,
    hiddenText
}) => {
    const [isTableVisible, setIsTableVisible] = useState(false)

    const toggleTableVisibility = () => {
        setIsTableVisible(!isTableVisible)
    }

    return (
        <div className="max-w-screen-lg mx-auto p-4">
            <button
                onClick={toggleTableVisibility}
                className="mb-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75">
                {isTableVisible ? hiddenText : showText}
            </button>

            {isTableVisible && (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                {headerElements.map((header, index) => (
                                    <th key={index} className="py-2 px-4 border-b">
                                        {header}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rowElements.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} className="py-2 px-4 border-b">
                                            {cell}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    )
}

export default ToggleableTable
