import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import 'index.css'
import reportWebVitals from 'reportWebVitals'
import { UserState } from 'interfaces'

// Auth
import { GoogleOAuthProvider } from '@react-oauth/google'
import useAuthCookie from 'hooks/useAuthCookie'

// Routing
import { BrowserRouter as Router, Outlet, Route, Navigate, Routes } from 'react-router-dom'
import * as route from 'constants/routes'

// Redux
import { Provider, useDispatch, useSelector } from 'react-redux'
import store from './redux/store'
import ACTION from './redux/Actions'

// Layouts
import Header from 'components/layouts/Header'
import Footer from 'components/layouts/Footer'

// Pages
import * as PAGE from 'pages'

// Background logic
import axios from 'axios'
import { IRootState } from 'redux/Types'

const PrivateRoutes = () => {
    const { authCookie } = useAuthCookie()

    if (!authCookie) {
        return <Navigate to={route.LOGIN} replace />
    }

    return <Outlet />
}

const App = () => {
    const dispatch = useDispatch()
    const { getAuthCookieValue } = useAuthCookie()
    const userState = useSelector((state: IRootState) => state.user) as UserState

    useEffect(() => {
        const fetchUserDetails = async () => {
            // Check if the auth cookie is present and state is empty string
            const cookieValue = getAuthCookieValue()
            console.log('cookieValue', cookieValue)

            if (cookieValue && userState.id === '') {
                try {
                    // Make API call to fetch user details
                    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/user-details`
                    const apiKey = process.env.REACT_APP_API_KEY

                    const response = await axios.get(apiUrl, {
                        headers: {
                            'X-API-Key': apiKey,
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${cookieValue}`
                        }
                    })
                    const userDetails = response.data.userDetails

                    dispatch({
                        type: ACTION.USER.ADD_USER_INFO,
                        payload: userDetails
                    })
                } catch (error) {
                    console.error('Error fetching user details:', error)
                }
            }
        }

        // Call fetchUserDetails function
        fetchUserDetails()
    }, [dispatch, getAuthCookieValue, userState.id])

    return (
        <section className="page-fill-background">
            <Router>
                <Header />
                <Routes>
                    <Route path={route.INDEX} element={<PAGE.Home />} />
                    <Route path={route.LOGIN} element={<PAGE.Login />} />
                    <Route path={route.TERMS_OF_USE} element={<PAGE.TermsOfUse />} />
                    <Route path={route.POLICY_PAGE} element={<PAGE.PrivacyPolicy />} />
                    <Route path={route.GUIDE_TO_PLATES} element={<PAGE.GuideToPlates />} />
                    <Route path={route.PLATE_PRINTERS} element={<PAGE.PlatePrinters />} />
                    <Route path={route.PLATE_CONTENT} element={<PAGE.Content />} />
                    <Route path={route.OWNERSHIP_GUIDE} element={<PAGE.OwnershipGuide />} />
                    <Route path={route.OTHER_PLATE_SITES} element={<PAGE.OtherPlateSites />} />
                    <Route
                        path={route.DVLA_AUCTION_PRICE_CALCULATOR}
                        element={<PAGE.DvlaAuctionPriceCalculator />}
                    />
                    <Route
                        path={route.FREQUENTLY_ASKED_QUESTIONS}
                        element={<PAGE.FrequentlyAskedQuestions />}
                    />
                    <Route path={route.MARKETPLACE} element={<PAGE.Marketplace />} />
                    <Route path="*" element={<PAGE.PageNotFound />} />
                    <Route element={<PrivateRoutes />}>
                        <Route element={<PAGE.Dashboard />} path={route.DASHBOARD} />
                        <Route element={<PAGE.Profile />} path={route.PROFILE} />
                    </Route>
                </Routes>
                <Footer />
            </Router>
        </section>
    )
}

const AppContainer = () => {
    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_LOGIN_KEY || ''}>
            <React.StrictMode>
                <Provider store={store}>
                    <App />
                </Provider>
            </React.StrictMode>
        </GoogleOAuthProvider>
    )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<AppContainer />)
