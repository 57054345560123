import React from 'react'
import './NumberPlate.css'

interface NumberPlateProps {
    text: string
}

const NumberPlate: React.FC<NumberPlateProps> = ({ text }) => {
    return <div className="number-plate">{text}</div>
}

export default NumberPlate
