import React from 'react'
import * as route from 'constants/routes'
import { Link } from 'react-router-dom'

const TermsOfUse = () => {
    return (
        <div className="max-w-3xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>

            <p className="mb-6">Welcome to RegWatch!</p>

            <p className="mb-6">
                These Terms of Use (&quot;Terms&quot;) govern your access to and use of the RegWatch
                website (the &quot;Website&quot;) provided by RegWatch, including any content,
                functionality, and services offered on or through the Website.
            </p>

            <p className="mb-6">
                By accessing or using the Website, you agree to be bound by these Terms. If you
                disagree with any part of the Terms, you may not access the Website.
            </p>

            <h2 className="text-xl font-bold mb-4">1. License Plate Search Service</h2>
            <p className="mb-6">
                RegWatch is a platform designed to facilitate the search and discovery of
                personalized license plates available for purchase within the United Kingdom. Our
                platform empowers users to effortlessly navigate through a diverse selection of
                unique and sought-after registration plates tailored to individual preferences.
            </p>

            <h2 className="text-xl font-bold mb-4">2. User Responsibilities</h2>
            <p className="mb-6">By using RegWatch, you agree to:</p>
            <ul className="list-disc list-inside mb-6">
                <li>Provide accurate and truthful information when using our platform.</li>
                <li>
                    Respect the intellectual property rights of others, including but not limited to
                    trademarks and copyrights.
                </li>
                <li>Use the Website in compliance with all applicable laws and regulations.</li>
                <li>
                    Refrain from engaging in any activity that may disrupt or interfere with the
                    proper functioning of the Website.
                </li>
            </ul>

            <h2 className="text-xl font-bold mb-4">3. Privacy Policy</h2>
            <p className="mb-6">
                Your privacy is important to us. Please review our{' '}
                <Link to={route.POLICY_PAGE} className="text-blue-500">
                    Privacy Policy
                </Link>{' '}
                to understand how we collect, use, and disclose your personal information.
            </p>

            <h2 className="text-xl font-bold mb-4">4. Intellectual Property</h2>
            <p className="mb-6">
                All content and materials available on the Website, including but not limited to
                text, graphics, logos, images, and software, are the property of RegWatch or its
                licensors and are protected by intellectual property laws. You may not use,
                reproduce, or distribute any content from the Website without prior written
                permission.
            </p>

            <h2 className="text-xl font-bold mb-4">5. Disclaimer of Warranties</h2>
            <p className="mb-6">
                RegWatch is provided &quot;as is&quot; and &quot;as available&quot; without
                warranties of any kind, whether express or implied. RegWatch does not warrant that
                the Website will be uninterrupted, error-free, or free from viruses or other harmful
                components.
            </p>

            <h2 className="text-xl font-bold mb-4">6. Limitation of Liability</h2>
            <p className="mb-6">
                In no event shall RegWatch be liable for any direct, indirect, incidental, special,
                or consequential damages arising out of or in any way connected with the use of the
                Website, whether based on contract, tort, strict liability, or otherwise.
            </p>

            <h2 className="text-xl font-bold mb-4">7. Changes to Terms</h2>
            <p className="mb-6">
                RegWatch reserves the right to modify or revise these Terms at any time without
                notice. Your continued use of the Website following the posting of any changes
                constitutes acceptance of those changes.
            </p>

            <h2 className="text-xl font-bold mb-4">8. Contact Us</h2>
            <p>
                If you have any questions or concerns about these Terms, please contact us at{' '}
                <a href="mailto:contact@regwatch.co.uk" className="text-blue-500">
                    contact@regwatch.co.uk
                </a>
                .
            </p>

            <p className="mt-8">Last updated: 29th March 2024</p>
        </div>
    )
}

export default TermsOfUse
