import React from 'react'
import YouTubeCard from './YouTubeCard'

const Content: React.FC = () => {
    return (
        <section className="bg-gray-100">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <section className="bg-gray-100">
                    <div className="px-4 mx-auto max-w-screen-xl flex flex-col lg:flex-row items-center text-center lg:text-left">
                        <div className="w-full lg:w-1/2 mb-8 lg:mb-0 items-center text-center">
                            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 mb-4">
                                Plate Content
                            </h1>
                        </div>
                        <div className="w-full lg:w-1/2">
                            <img
                                className="w-full h-auto rounded-lg shadow-lg"
                                src="/images/plates/plate-background-v12 sft.PNG"
                                alt="Hero"
                            />
                        </div>
                    </div>
                </section>

                <div className="p-4 flex flex-wrap">
                    <YouTubeCard
                        title="FERRARI WON’T FIX MY 812 GTS… SO I’M FIGHTING BACK!"
                        thumbnailUrl="/images/plates/content/pc-001_video-img.png"
                        description="Nico spends the day at a Ferrari charity auction day and has the chance to purchase a large number of press car plates."
                        videoOwner="Nico Leonard (Uncut)"
                        videoUrl="https://www.youtube.com/watch?v=CzIdyoS6aq0"
                    />
                    <YouTubeCard
                        title="Are NUMBER PLATES worth the INVESTMENT!?"
                        thumbnailUrl="/images/plates/content/pc-005_video-img.png"
                        description="Number plates are good fun and my father has been actively going to DVLA personalised registration auctions for many years. I have a fair few number plates and wanted to share with you my view on them - as some say its a waste of money...what do you think?"
                        videoOwner="Lord Aleem"
                        videoUrl="https://www.youtube.com/watch?v=xGreT74OHrg&pp=ygUUcHJpdmF0ZSBudW1iZXIgcGxhdGU%3D"
                    />
                    <YouTubeCard
                        title="A tour of our personalised registration plate collection"
                        thumbnailUrl="/images/plates/content/pc-002_video-img.png"
                        description="A quick tour and walkaround of our four UK private number plates! [note: gives some tips around getting plates etc]"
                        videoOwner="Felix Gardner"
                        videoUrl="https://youtu.be/o4GyXGuGww4?si=oxcTIDxDpaOK6LVR"
                    />
                    <YouTubeCard
                        title="UK REGISTRATION PLATE LAWS - Part 1"
                        thumbnailUrl="/images/plates/content/pc-003_video-img.png"
                        description="Discuss everything Number Plates and the rules surrounding them here in the UK. From your bog standard plates, shortened plates all the way to your custom 3D/4D plates, we have got you covered with everything you need to know."
                        videoOwner="GCM (George's Car Media)"
                        videoUrl="https://www.youtube.com/watch?v=pJh9xjnZ2vY&pp=ygUUcHJpdmF0ZSBudW1iZXIgcGxhdGU%3D"
                    />
                    <YouTubeCard
                        title="UK REGISTRATION PLATE LAWS - Part 2"
                        thumbnailUrl="/images/plates/content/pc-004_video-img.png"
                        description="We take a look at the rules regarding square number plates, metal pressed number plates, JDM plates & stick on number plates"
                        videoOwner="GCM (George's Car Media)"
                        videoUrl="https://www.youtube.com/watch?v=PAezMXD4Da0"
                    />
                </div>
            </div>
        </section>
    )
}

export default Content
