import React, { ReactNode } from 'react'

interface FeatureCardProps {
    svg: ReactNode
    title: string
    text: string
}

const FeatureCard: React.FC<FeatureCardProps> = ({ svg, title, text }) => {
    return (
        <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-900 lg:h-12 lg:w-12">
                {svg}
            </div>
            <h3 className="mb-2 text-xl font-bold text-white">{title}</h3>
            <p className="text-gray-400">{text}</p>
        </div>
    )
}

const Features: React.FC = () => {
    return (
        <section className="bg-gray-900">
            <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                <div className="max-w-screen-md mb-8 lg:mb-16">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">
                        Designed for search needs like yours
                    </h2>
                    <p className="text-gray-400 sm:text-xl">
                        Empowering your number plate journey with cutting-edge features designed for
                        seamless exploration and personalised discovery.
                    </p>
                </div>
                <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
                    <FeatureCard
                        svg={
                            <svg
                                className="w-5 h-5 text-primary-300 lg:w-6 lg:h-6"
                                fill="currentColor"
                                viewBox="0 0 20 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <path d="M0,0h24v24H0V0z" fill="none" />
                                </g>
                                <g>
                                    <path d="M7,9H2V7h5V9z M7,12H2v2h5V12z M20.59,19l-3.83-3.83C15.96,15.69,15.02,16,14,16c-2.76,0-5-2.24-5-5s2.24-5,5-5s5,2.24,5,5 c0,1.02-0.31,1.96-0.83,2.75L22,17.59L20.59,19z M17,11c0-1.65-1.35-3-3-3s-3,1.35-3,3s1.35,3,3,3S17,12.65,17,11z M2,19h10v-2H2 V19z" />
                                </g>
                            </svg>
                        }
                        title="Intuitive Search Engine"
                        text="Our powerful search engine allows users to effortlessly explore a vast database of number plates, refining results based on specific criteria and preferences."
                    />
                    <FeatureCard
                        svg={
                            <svg
                                className="w-5 h-5 text-primary-300 lg:w-6 lg:h-6"
                                fill="currentColor"
                                viewBox="0 0 20 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
                                <path d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
                            </svg>
                        }
                        title="Real-Time Availability Status"
                        text="Stay informed with up-to-the-minute availability status for each number plate, ensuring you never miss out on the perfect registration for your vehicle."
                    />
                    <FeatureCard
                        svg={
                            <svg
                                className="w-5 h-5 text-primary-300 lg:w-6 lg:h-6"
                                fill="currentColor"
                                viewBox="0 0 20 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z" />
                            </svg>
                        }
                        title="Personalised Alerts"
                        text="Create personalised alerts for specific search criteria, and receive notifications the moment a matching number plate becomes available, keeping you one step ahead."
                    />
                    <FeatureCard
                        svg={
                            <svg
                                className="w-5 h-5 text-primary-300 lg:w-6 lg:h-6"
                                fill="currentColor"
                                viewBox="0 0 20 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z" />
                            </svg>
                        }
                        title="User-Friendly Dashboard"
                        text="Enjoy a seamless and user-friendly experience with an intuitive dashboard, providing easy access to your saved searches, alerts, and favourite number plates."
                    />
                    <FeatureCard
                        svg={
                            <svg
                                className="w-5 h-5 text-primary-300 lg:w-6 lg:h-6"
                                fill="currentColor"
                                viewBox="0 0 20 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <g>
                                    <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                    <path d="M14,21c1.93,0,3.62-1.17,4-3l-1.75-0.88C16,18.21,15.33,19,14,19l-4.9,0c0.83-1,1.5-2.34,1.5-4c0-0.35-0.03-0.69-0.08-1 L14,14v-2l-4.18,0C9,10.42,8,9.6,8,8c0-1.93,1.57-3.5,3.5-3.5c1.5,0,2.79,0.95,3.28,2.28L16.63,6c-0.8-2.05-2.79-3.5-5.13-3.5 C8.46,2.5,6,4.96,6,8c0,1.78,0.79,2.9,1.49,4L6,12v2l2.47,0c0.08,0.31,0.13,0.64,0.13,1c0,2.7-2.6,4-2.6,4v2H14z" />
                                </g>
                            </svg>
                        }
                        title="Transparent Pricing Information"
                        text="We believe in transparency, providing clear and competitive pricing information for each number plate, so you can make informed decisions without any surprises."
                    />
                    <FeatureCard
                        svg={
                            <svg
                                className="w-5 h-5 text-primary-300 lg:w-6 lg:h-6"
                                fill="currentColor"
                                viewBox="0 0 20 24"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h24v24H0z" fill="none" />
                                <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                            </svg>
                        }
                        title="Privacy Assurance"
                        text="Your privacy matters. RegWatch is committed to ensuring a confidential and secure environment, providing peace of mind throughout your number plate exploration and acquisition journey."
                    />
                </div>
            </div>
        </section>
    )
}

export default Features
