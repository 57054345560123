import React from 'react'

const PrivacyPolicy = () => {
    return (
        <div className="max-w-3xl mx-auto p-6">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

            <p className="mb-6">
                At RegWatch, we are committed to protecting your privacy. This Privacy Policy
                outlines how we collect, use, and disclose your personal information when you use
                our website (the &quot;Website&quot;).
            </p>

            <h2 className="text-xl font-bold mb-4">Information We Collect</h2>
            <p className="mb-6">
                We may collect personal information that you provide to us when using our Website,
                such as your name, email address, and any other information you choose to share with
                us.
            </p>

            <h2 className="text-xl font-bold mb-4">How We Use Your Information</h2>
            <p className="mb-6">We may use the information we collect from you to:</p>
            <ul className="list-disc list-inside mb-6">
                <li>Provide and maintain our services.</li>
                <li>Personalize your experience and improve our Website.</li>
                <li>Respond to your inquiries and provide customer support.</li>
                <li>Send you promotional emails and updates about our services.</li>
                <li>Comply with legal obligations.</li>
            </ul>

            <h2 className="text-xl font-bold mb-4">Disclosure of Your Information</h2>
            <p className="mb-6">
                We may share your personal information with third-party service providers who assist
                us in operating our Website and providing our services. We may also disclose your
                information in response to legal requests or to protect our rights and interests.
            </p>

            <h2 className="text-xl font-bold mb-4">Data Security</h2>
            <p className="mb-6">
                We take reasonable precautions to protect your personal information from
                unauthorized access, use, or disclosure. However, no method of transmission over the
                Internet or electronic storage is 100% secure, and we cannot guarantee absolute
                security.
            </p>

            <h2 className="text-xl font-bold mb-4">Your Choices</h2>
            <p className="mb-6">
                You may choose not to provide certain personal information, but this may limit your
                ability to use certain features of our Website. You can opt-out of receiving
                promotional emails from us by following the unsubscribe instructions included in
                those emails.
            </p>

            <h2 className="text-xl font-bold mb-4">Changes to This Privacy Policy</h2>
            <p className="mb-6">
                We reserve the right to update or change our Privacy Policy at any time. Your
                continued use of the Website after we post any modifications to the Privacy Policy
                on this page will constitute your acknowledgment of the modifications and your
                consent to abide and be bound by the modified Privacy Policy.
            </p>

            <h2 className="text-xl font-bold mb-4">Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy, please contact us
                at{' '}
                <a href="mailto:contact@regwatch.co.uk" className="text-blue-500">
                    contact@regwatch.co.uk
                </a>
                .
            </p>

            <p className="mt-8">Last updated: [Date]</p>
        </div>
    )
}

export default PrivacyPolicy
