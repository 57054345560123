import React from 'react'

interface TableRowProps {
    hammerPrice: number
    totalPrice: number
    hammerPriceVat: number
    buyersPremium: number
    buyersPremiumVat: number
    assignmentFee: number
}

const formatNumberWithCommas = (number: number | string): string => {
    // Check if the input is a number
    if (typeof number === 'string') {
        number = parseFloat(number)
    }

    if (isNaN(number)) {
        return 'Invalid number'
    }

    // Format the number with commas
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

const TableRow: React.FC<TableRowProps> = ({
    hammerPrice,
    totalPrice,
    hammerPriceVat,
    buyersPremium,
    buyersPremiumVat,
    assignmentFee
}) => {
    return (
        <tr className="bg-white border-b bg-gray-800 border-gray-700 hover:bg-gray-600">
            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-gray-800">
                £{formatNumberWithCommas(hammerPrice)}
            </th>
            <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap text-gray-800">
                £{formatNumberWithCommas(totalPrice)}
            </th>
            <td className="px-6 py-4">£{formatNumberWithCommas(hammerPriceVat)}</td>
            <td className="px-6 py-4">£{formatNumberWithCommas(buyersPremium)}</td>
            <td className="px-6 py-4">£{formatNumberWithCommas(buyersPremiumVat)}</td>
            <td className="px-6 py-4">£{formatNumberWithCommas(assignmentFee)}</td>
        </tr>
    )
}

export default TableRow
