import React, { useState } from 'react'

const formatCurrency = (value: number): string => {
    return value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

const DvlaActionDynamicPriceCalculator: React.FC = () => {
    const [hammerPrice, setHammerPrice] = useState<number>(0)
    const vatRate = 0.2
    const buyersPremiumRate = 0.07
    const assignmentFee = 80

    const vat: number = hammerPrice * vatRate
    const buyersPremium: number = hammerPrice * buyersPremiumRate
    const buyersPremiumVAT: number = buyersPremium * vatRate
    const total: number = hammerPrice + vat + buyersPremium + buyersPremiumVAT + assignmentFee

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setHammerPrice(parseFloat(value))
    }

    return (
        <div className="p-4 bg-gray-100">
            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label htmlFor="hammerPrice" className="block mb-2">
                        Hammer Price:
                    </label>
                    <input
                        type="number"
                        id="hammerPrice"
                        value={hammerPrice}
                        onChange={handleInputChange}
                        placeholder="Hammer price"
                        className="border rounded-md p-2 w-full"
                    />
                </div>
                <div>
                    <p className="mb-2">VAT: £{formatCurrency(vat)}</p>
                    <p className="mb-2">Buyers Premium: £{formatCurrency(buyersPremium)}</p>
                    <p className="mb-2">Buyers Premium VAT: £{formatCurrency(buyersPremiumVAT)}</p>
                    <p className="mb-2">Assignment Fee: £{formatCurrency(assignmentFee)}</p>
                    <h3 className="text-xl font-semibold">Total: £{formatCurrency(total)}</h3>
                </div>
            </div>
        </div>
    )
}

export default DvlaActionDynamicPriceCalculator
