import { combineReducers } from 'redux'
import userReducer from './userReducer'
import searchTermsReducer from './searchTermsReducer'
import searchResultsReducer from './searchResultsReducer'

const rootReducer = combineReducers({
    user: userReducer,
    searchTerms: searchTermsReducer,
    searchResults: searchResultsReducer
})

export default rootReducer
