import React, { useState } from 'react'
import ResultsComponent from './Results'
import SearchTermsComponent from './SearchTerms'

// Define enum for tab values
enum Tab {
    Results = 'Results',
    SearchTerms = 'Search Terms'
}

const Dashboard: React.FC = () => {
    const [activeTab, setActiveTab] = useState<Tab>(Tab.Results)

    const handleTabClick = (tab: Tab) => {
        setActiveTab(tab)
    }

    return (
        <div className="bg-white w-full flex flex-col md:flex-row gap-5 px-3 md:px-16 lg:px-28 text-[#161931]">
            <aside className="md:w-1/3 lg:w-1/4 md:block">
                <div className="sticky top-0 md:top-12 flex flex-col md:gap-4 gap-2 p-4 md:text-sm border-b md:border-r border-indigo-100 bg-white md:bg-transparent z-10">
                    <div className="flex flex-col md:items-stretch items-center md:gap-4 gap-2">
                        <h2 className="text-2xl font-semibold">Dashboard</h2>
                        <div className="flex md:flex-col flex-row gap-2 md:gap-4 w-full">
                            <button
                                className={`px-3 py-2.5 font-bold bg-white text-indigo-900 border rounded-full ${
                                    activeTab === Tab.Results ? 'bg-indigo-200' : ''
                                } w-full md:w-auto`}
                                onClick={() => handleTabClick(Tab.Results)}>
                                Results
                            </button>
                            <button
                                className={`px-3 py-2.5 font-semibold bg-white text-indigo-900 border rounded-full hover:text-indigo-900 hover:border ${
                                    activeTab === Tab.SearchTerms ? 'bg-indigo-200' : ''
                                } w-full md:w-auto`}
                                onClick={() => handleTabClick(Tab.SearchTerms)}>
                                Search Terms
                            </button>
                        </div>
                    </div>
                </div>
            </aside>
            <main className="w-full min-h-screen py-1 md:w-2/3 lg:w-3/4 overflow-x-auto">
                <div className="p-0 md:p-4">
                    <div className="w-full max-w-full px-0 md:px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
                        {activeTab === Tab.Results && <ResultsComponent />}
                        {activeTab === Tab.SearchTerms && <SearchTermsComponent />}
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Dashboard
