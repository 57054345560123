/* Footer Design taken from https://flowbite.com/blocks/marketing/footer/ */

import React from 'react'
import * as route from 'constants/routes'
import { Link } from 'react-router-dom'

interface FooterLinkProps {
    link: string
    text: string
}

const FooterLink: React.FC<FooterLinkProps> = ({ link, text }) => {
    return (
        <li>
            <Link to={link} className="mr-4 hover:underline md:mr-6">
                {text}
            </Link>
        </li>
    )
}

const Footer: React.FC = () => {
    return (
        <footer className="p-4 bg-gray-800 md:p-8 lg:p-10">
            <div className="mx-auto max-w-screen-xl text-center">
                <Link
                    to={route.INDEX}
                    className="flex justify-center items-center text-2xl text-white font-semibold text-gray-900">
                    <img
                        src="/logo/RegWatch_logo_500x500.png"
                        className="mr-2 h-8"
                        alt="RegWatch Logo"
                    />
                    RegWatch
                </Link>
                <p className="my-6 text-gray-400">
                    Your ultimate destination to effortlessly track and find personalised license
                    plates for sale across the UK
                </p>
                <ul className="flex flex-wrap justify-center items-center mb-6 text-white">
                    <FooterLink link={route.FREQUENTLY_ASKED_QUESTIONS} text="FAQs" />
                    <FooterLink link={route.TERMS_OF_USE} text="Terms of use" />
                    <FooterLink link={route.POLICY_PAGE} text="Privacy Policy" />
                </ul>
                <div className="mb-6">
                    <span className="text-sm text-gray-400 sm:text-center">
                        © 2024{' '}
                        <Link to={route.INDEX} className="hover:underline">
                            RegWatch™
                        </Link>
                        . All Rights Reserved.
                    </span>
                </div>
                <div className="flex sm:justify-center space-x-6">
                    <a
                        href="https://www.facebook.com/RegWatch/"
                        className="text-gray-500 hover:text-gray-900"
                        target="_blank"
                        rel="noreferrer">
                        <svg
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true">
                            <path
                                fillRule="evenodd"
                                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                                clipRule="evenodd"></path>
                        </svg>
                    </a>
                    <a
                        href="https://instagram.com/RegWatch/"
                        className="text-gray-500 hover:text-gray-900"
                        target="_blank"
                        rel="noreferrer">
                        <svg
                            className="h-5 w-5"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true">
                            <path
                                fillRule="evenodd"
                                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                clipRule="evenodd"></path>
                        </svg>
                    </a>
                    <a
                        href="https://twitter.com/RegWatch/"
                        className="text-gray-500 hover:text-gray-900 my-auto"
                        target="_blank"
                        rel="noreferrer">
                        <svg
                            className="h-4 w-4"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                            aria-hidden="true">
                            <path d="M 14.285156 10.15625 L 23.21875 0 L 21.101562 0 L 13.34375 8.820312 L 7.148438 0 L 0 0 L 9.371094 13.335938 L 0 23.988281 L 2.117188 23.988281 L 10.308594 14.675781 L 16.851562 23.988281 L 24 23.988281 L 14.28125 10.15625 Z M 11.382812 13.453125 L 10.433594 12.125 L 2.878906 1.558594 L 6.132812 1.558594 L 12.226562 10.085938 L 13.175781 11.414062 L 21.101562 22.5 L 17.847656 22.5 Z M 11.382812 13.453125"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer
