import React from 'react'
import '../icons.css'

const sizeClasses = {
    'x-small': 'icon-x-small',
    small: 'icon-small',
    medium: 'icon-medium',
    large: 'icon-large',
    'x-large': 'icon-x-large'
}

interface IconProps {
    size: 'x-small' | 'small' | 'medium' | 'large' | 'x-large'
}

const CrossIcon: React.FC<IconProps> = ({ size }) => {
    const sizeClass = sizeClasses[size] || sizeClasses['medium']

    return <img src="/images/icons/cross-svgrepo-com.svg" alt="cross icon" className={sizeClass} />
}

export default CrossIcon
