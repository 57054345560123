import React, { useState } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import * as route from 'constants/routes'
import { useNavigate } from 'react-router-dom'

import useAuthCookie from 'hooks/useAuthCookie'

import axios from 'axios'
import LoadSpinner from 'components/LoadSpinner'
import { useDispatch } from 'react-redux'
import ACTION from '../../redux/Actions'

enum ErrorType {
    General,
    NotApproved
}

// Define the type for the error state
interface ErrorState {
    error: boolean
    type: ErrorType | null // Use the ErrorType enum as the type
}

const Login: React.FC = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { setAuthCookie } = useAuthCookie()

    const [loginError, setLoginError] = useState<ErrorState>({ error: false, type: null })
    const [isLoading, setIsLoading] = useState(false)

    const login = useGoogleLogin({
        onSuccess: async (credentialResponse) => {
            try {
                console.log(credentialResponse)
                console.log('Google Access Token', credentialResponse.access_token)

                const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/auth/login`
                const apiKey = process.env.REACT_APP_API_KEY

                const res = await axios.post(
                    apiUrl,
                    {
                        accessToken: credentialResponse.access_token
                    },
                    {
                        headers: {
                            'X-API-Key': apiKey,
                            'Content-Type': 'application/json'
                        }
                    }
                )

                if (res.status === 200) {
                    console.log(res)

                    setAuthCookie(res.data.idToken)

                    dispatch({
                        type: ACTION.USER.ADD_USER_INFO,
                        payload: res.data.userDetails
                    })

                    navigate(`../${route.DASHBOARD}`)
                }
            } catch (error: unknown) {
                console.error(error)

                if (axios.isAxiosError(error)) {
                    if (error?.response?.status === 401) {
                        setLoginError({ error: true, type: ErrorType.General })
                        setIsLoading(false)
                        return
                    }

                    if (error?.response?.status === 403) {
                        setLoginError({ error: true, type: ErrorType.NotApproved })
                        setIsLoading(false)
                        return
                    }

                    setLoginError({ error: true, type: ErrorType.General })
                    setIsLoading(false)
                    return
                } else {
                    // Just a stock error
                }
            }
        },
        onError: () => {
            setLoginError({ error: false, type: ErrorType.General })
            setIsLoading(false)
            console.log('Login Failed')
        }
    })

    return (
        <div className="h-screen w-screen">
            <div className="grid place-items-center backdrop-blur-sm top-0 right-0 left-0 z-50 w-full inset-0 h-modal h-full justify-center items-center">
                <div className="relative container m-auto px-6">
                    <div className="m-auto md:w-7/12">
                        <div className="rounded-xl bg-gray-800">
                            <div className="p-8">
                                <div className="space-y-4">
                                    <img
                                        src="/logo/RegWatch_logo_500x500.png"
                                        loading="lazy"
                                        className="w-10"
                                        alt="RegWatch Logo"
                                    />
                                    <h2 className="mb-8 text-2xl text-white">
                                        Log in to unlock the <br />
                                        best of RegWatch.
                                    </h2>
                                </div>
                                <div className="mt-10 grid space-y-4">
                                    <button
                                        className="group h-12 px-6 border-2 border-gray-300 rounded-full transition duration-300 hover:border-blue-400 focus:bg-blue-50 active:bg-blue-100"
                                        disabled={isLoading}
                                        onClick={() => {
                                            setIsLoading(true)
                                            setLoginError({ error: false, type: null })
                                            login()
                                        }}>
                                        <div className="relative flex items-center space-x-4 justify-center">
                                            <img
                                                src="https://www.svgrepo.com/show/475656/google-color.svg"
                                                className="absolute left-0 w-5"
                                                alt="google logo"
                                            />
                                            <span className="block w-max font-semibold tracking-wide text-white text-sm transition duration-300 group-hover:text-blue-600 sm:text-base">
                                                Continue with Google
                                            </span>
                                        </div>
                                    </button>
                                    {loginError.error && loginError.type === ErrorType.General && (
                                        <div
                                            className="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-red-400 border-red-800"
                                            role="alert">
                                            <span className="sr-only">Error</span>
                                            <p className="text-center">
                                                An error occurred during login, please try again
                                            </p>
                                        </div>
                                    )}
                                    {loginError.error &&
                                        loginError.type === ErrorType.NotApproved && (
                                            <div
                                                className="flex items-center p-4 mb-4 text-sm border rounded-lg bg-gray-800 text-yellow-300 border-yellow-800"
                                                role="alert">
                                                <span className="sr-only">Error</span>
                                                <p className="text-center">
                                                    Unable to log into RegWatch due to not being an
                                                    approved user, please contact a member of the
                                                    team to request access.
                                                </p>
                                            </div>
                                        )}
                                    <div className="flex items-center justify-center">
                                        {isLoading && <LoadSpinner />}
                                    </div>
                                </div>
                                <div className="mt-14 space-y-4 py-3 text-white text-center">
                                    <p className="text-xs">
                                        By proceeding, you agree to our{' '}
                                        <a href={route.TERMS_OF_USE} className="underline">
                                            Terms of Use{' '}
                                        </a>
                                        and confirm you have read our{' '}
                                        <a href={route.POLICY_PAGE} className="underline">
                                            Privacy and Cookie Statement
                                        </a>
                                        .
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
