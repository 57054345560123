import React from 'react'

interface SellerCardProps {
    seller: string
    details: string
    logoImg: string
    webLink: string
    sellingMethods: string
}

const SellerCard: React.FC<SellerCardProps> = ({
    seller,
    details,
    logoImg,
    webLink,
    sellingMethods
}) => {
    return (
        <div className="flex flex-col md:flex-row items-center border p-4 bg-white shadow-md my-4">
            <div className="flex-shrink-0 mb-4 md:mb-0">
                <a href={webLink} className="block" target="_blank" rel="noreferrer">
                    <img src={logoImg} alt="Logo" className="w-32 h-24 object-scale-down" />
                </a>
            </div>
            <div className="ml-0 md:ml-6 text-center md:text-left">
                <div className="font-bold text-lg md:text-xl">{seller}</div>
                <div className="mt-2">
                    <span className="block">{details}</span>
                </div>
                <div className="mt-2 font-semibold">{sellingMethods}</div>
            </div>
        </div>
    )
}

export default SellerCard
