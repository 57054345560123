export const currentStyleHeaderElements = [
    'Year Registered',
    'March 1st to August 31st',
    'September 1st to February 28/29th'
]
export const currentStyleRowElements = [
    ['2001/02', 'Y*', '51'],
    ['2002/03', '02', '52'],
    ['2003/04', '03', '53'],
    ['2004/05', '04', '54'],
    ['2005/06', '05', '55'],
    ['2006/07', '06', '56'],
    ['2007/08', '07', '57'],
    ['2008/09', '08', '58'],
    ['2009/10', '09', '59'],
    ['2010/11', '10', '60'],
    ['2011/12', '11', '61'],
    ['2012/13', '12', '62'],
    ['2013/14', '13', '63'],
    ['2014/15', '14', '64'],
    ['2015/16', '15', '65'],
    ['2016/17', '16', '66'],
    ['2017/18', '17', '67'],
    ['2018/19', '18', '68'],
    ['2019/20', '19', '69'],
    ['2020/21', '20', '70'],
    ['2021/22', '21', '71'],
    ['2022/23', '22', '72'],
    ['2023/24', '23', '73'],
    ['2024/25', '24', '74'],
    ['2025/26', '25', '75'],
    ['2026/27', '26', '76'],
    ['2027/28', '27', '77'],
    ['2028/29', '28', '78'],
    ['2029/30', '29', '79'],
    ['2030/31', '30', '80'],
    ['2031/32', '31', '81'],
    ['2032/33', '32', '82'],
    ['2033/34', '33', '83'],
    ['2034/35', '34', '84'],
    ['2035/36', '35', '85'],
    ['2036/37', '36', '86'],
    ['2037/38', '37', '87'],
    ['2038/39', '38', '88'],
    ['2039/40', '39', '89'],
    ['2040/41', '40', '90'],
    ['2041/42', '41', '91'],
    ['2042/43', '42', '92'],
    ['2043/44', '43', '93'],
    ['2044/45', '44', '94'],
    ['2045/46', '45', '95'],
    ['2046/47', '46', '96'],
    ['2047/48', '47', '97'],
    ['2048/49', '48', '98'],
    ['2049/50', '49', '99'],
    ['2050/51', '50', '00']
]

export const prefixHeaderElements = ['Age Identifier', 'Date Registered']
export const prefixRowElements = [
    ['A', 'August 1st 1983 to July 31st 1984'],
    ['B', 'August 1st 1984 to July 31st 1985'],
    ['C', 'August 1st 1985 to July 31st 1986'],
    ['D', 'August 1st 1986 to July 31st 1987'],
    ['E', 'August 1st 1987 to July 31st 1988'],
    ['F', 'August 1st 1988 to July 31st 1989'],
    ['G', 'August 1st 1989 to July 31st 1990'],
    ['H', 'August 1st 1990 to July 31st 1991'],
    ['J', 'August 1st 1991 to July 31st 1992'],
    ['K', 'August 1st 1992 to July 31st 1993'],
    ['L', 'August 1st 1993 to July 31st 1994'],
    ['M', 'August 1st 1994 to July 31st 1995'],
    ['N', 'August 1st 1995 to July 31st 1996'],
    ['P', 'August 1st 1996 to July 31st 1997'],
    ['R', 'August 1st 1997 to July 31st 1998'],
    ['S', 'August 1st 1998 to February 28th 1999'],
    ['T', 'March 1st 1999 to August 31st 1999'],
    ['V', 'September 1st 1999 to February 29th 2000'],
    ['W', 'March 1st 2000 to August 31st 2000'],
    ['X', 'September 1st 2000 to February 28th 2001'],
    ['Y', 'March 1st 2001 to August 31st 2001']
]

export const suffixHeaderElements = ['Age Identifier', 'Date Registered']
export const suffixRowElements = [
    ['A', 'February 1st 1963 to December 31st 1963'],
    ['B', 'January 1st 1964 to December 31st 1964'],
    ['C', 'January 1st 1965 to December 31st 1965'],
    ['D', 'January 1st 1966 to December 31st 1966'],
    ['E', 'January 1st 1967 to July 31st 1967'],
    ['F', 'August 1st 1967 to July 31st 1968'],
    ['G', 'August 1st 1968 to July 31st 1969'],
    ['H', 'August 1st 1969 to July 31st 1970'],
    ['J', 'August 1st 1970 to July 31st 1971'],
    ['K', 'August 1st 1971 to July 31st 1972'],
    ['L', 'August 1st 1972 to July 31st 1973'],
    ['M', 'August 1st 1973 to July 31st 1974'],
    ['N', 'August 1st 1974 to July 31st 1975'],
    ['P', 'August 1st 1975 to July 31st 1976'],
    ['R', 'August 1st 1976 to July 31st 1977'],
    ['S', 'August 1st 1977 to July 31st 1978'],
    ['T', 'August 1st 1978 to July 31st 1979'],
    ['V', 'August 1st 1979 to July 31st 1980'],
    ['W', 'August 1st 1980 to July 31st 1981'],
    ['X', 'August 1st 1981 to July 31st 1982'],
    ['Y', 'August 1st 1982 to July 31st 1983']
]
