import React from 'react'
import { REGWATCH_IMAGES } from 'constants/assets'
import Pricing from './Pricing'
import Features from './Features'
import About from './About'

const Home: React.FC = () => {
    const imageUrl: string =
        REGWATCH_IMAGES[Math.floor(Math.random() * REGWATCH_IMAGES.length)] ||
        'http://localhost:3000/images/cars/regwatch_plates/REGWATCH_CarReg_0.png'

    return (
        <div>
            {/* Heads up! 👋 This component comes with some `rtl` classes. Please remove them if they are not needed in your project.*/}
            <section
                className="relative bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${imageUrl})` }}>
                {/* Hero Section taken from - https://www.hyperui.dev/components/marketing/banners#component-3 */}
                <div className="absolute inset-0 bg-gradient-to-r from-white/25 to-white/25 bg-white/25"></div>

                <div className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen lg:items-center lg:px-8">
                    <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
                        <h1 className="text-3xl font-extrabold sm:text-5xl">
                            Let us find your perfect
                            <strong className="block font-extrabold text-primary-600">
                                {' '}
                                Private Registration{' '}
                            </strong>
                        </h1>

                        <p className="mt-4 max-w-lg sm:text-xl/relaxed font-bold">
                            Your ultimate destination to effortlessly track and find personalised
                            number plates for sale across the UK
                        </p>
                    </div>
                </div>
            </section>
            <About />
            <Features />
            <Pricing />
        </div>
    )
}

export default Home
