import React from 'react'
import CrossIcon from 'components/Assets/Icons/CrossIcon'
import TickIcon from 'components/Assets/Icons/TickIcon'

interface TableRowProps {
    seller: string
    sellerLogoSrc: string
    sellerWebLink: string
    plate2D: boolean
    plate3D: boolean
    plate4D: boolean
    plateMetal: boolean
    plateShow: boolean
}

const TableRow: React.FC<TableRowProps> = ({
    seller,
    sellerLogoSrc,
    sellerWebLink,
    plate2D,
    plate3D,
    plate4D,
    plateMetal,
    plateShow
}) => {
    return (
        <tr className="bg-white border-b bg-gray-800 border-gray-700">
            <td className="px-6 py-4" data-label="Seller">
                <img className="w-36 h-auto rounded-lg shadow-lg" src={sellerLogoSrc} alt="Hero" />
                <a
                    href={sellerWebLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500 hover:underline">
                    {seller}
                </a>
            </td>
            <td className="px-6 py-4" data-label="Standard 2D">
                {plate2D ? <TickIcon size="small" /> : <CrossIcon size="medium" />}
            </td>
            <td className="px-6 py-4" data-label="3D">
                {plate3D ? <TickIcon size="small" /> : <CrossIcon size="medium" />}
            </td>
            <td className="px-6 py-4" data-label="4D">
                {plate4D ? <TickIcon size="small" /> : <CrossIcon size="medium" />}
            </td>
            <td className="px-6 py-4" data-label="Metal Plates">
                {plateMetal ? <TickIcon size="small" /> : <CrossIcon size="medium" />}
            </td>
            <td className="px-6 py-4" data-label="Show Plates">
                {plateShow ? <TickIcon size="small" /> : <CrossIcon size="medium" />}
            </td>
        </tr>
    )
}

export default TableRow
